export const configuration = {
  cedApiKey: "j1vVN75pZZXklszGnKh81YgQTLzmHz8qbWKnCaSLSDVtrexoVjSWvNa8N1N4s1sbUVvd4ocw7KQIYvKhMjdJvGOayw5gEkReUXhj",
  environment: "production",
  version: "1.0.12",
  backend: {
    graphqlUrl: "https://newslit.co/graphql",
    graphiqlUrl: "https://newslit.co/_ah/graphql",
    externalLoginUrl: "https://newslit.co/auth/external",
    baseUrl: "https://newslit.co",
  },
  launchdarkly: {
    clientSideId: "5b1d835e0b0fdd34a784f99f",
    sdkKey: "sdk-2d37f499-d54e-4ef1-8f1e-0ddb86cecca1",
  },
  newslitUikit: {
    registry: "https://registry.npmjs.org/",
  },
  spa: {
    url: "https://app.newslit.co",
    hostingUrl: "https://newslit-prod.web.app",
  },
};